<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-form label-width="100px">
          <el-form-item label="排序">
            <el-input-number v-model="form.sort"></el-input-number>
          </el-form-item>
          <el-form-item label="分类">
            <el-cascader style="width: 100%"
                         v-model="cate_choose"
                         :options="cate_opt_list"
                         :props="{value:'id',label:'title'}"></el-cascader>
          </el-form-item>
          <el-form-item v-loading="themeLoading" label="专题">
            <el-select @change="themeChange" style="width: 100%" v-model="theme_val" multiple filterable allow-create default-first-option placeholder="请选择专题标签">
              <el-option v-for="item in theme_opt" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="分享描述">
            <el-input v-model="form.share_desc"></el-input>
          </el-form-item>
          <el-form-item label="商品图">
            <y_upload_img v-model="form.pic"></y_upload_img>
          </el-form-item>
          <el-form-item v-if="form.type ===  2" label="首页缩略图">
            <y_upload_img v-model="form.home_pic"></y_upload_img>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form label-width="100px">
          <el-form-item label="售价">
            <el-input-number :disabled="form.spec" :min="0" v-model="form.fee"></el-input-number>
            标价 <el-input-number :disabled="form.spec" :min="0" v-model="form.original_price"></el-input-number>
            虚拟销量 <el-input-number :min="0" v-model="form.fake_sales"></el-input-number>
          </el-form-item>
          <el-form-item label="赠送积分" v-if="pointsMallEnable">
            <el-input-number :min="0" v-model="pointsMallGiftFormEdit.gift"></el-input-number>
          </el-form-item>
          <el-form-item label="运费模版">
            <y_choose_shipping v-model="form.shipping_id"></y_choose_shipping>
            <div class="y-desc">不选择运费模版时免运费</div>
          </el-form-item>
          <el-form-item label="购买权限">
            <el-select @change="scopeChange" style="width: 100%" v-model="scope_show" multiple placeholder="请选择">
              <el-option v-for="item in scope_option" :key="item.level" :label="item.title" :value="item.level"></el-option>
            </el-select>
            <div class="y-desc">限制仅指定权限可购买，为空时不限制</div>
          </el-form-item>
          <el-form-item label="商品类型">
            <el-radio-group v-model="form.type">
              <el-radio v-for="(item,key) in goods_type_opt" :label="item.value" :key="key">{{item.title}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上架">
            <div class="flex-def">
              <div><el-switch v-model="form.status"></el-switch></div>
              <div style="margin-left: 2rem">推荐<el-switch v-model="form.recommend"></el-switch></div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button @click="submit" type="primary">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import {levelOption} from "@/common/mod/user";
import y_choose_shipping from "@/components/shop/y_choose_shipping";
export default {
  name: "basic",
  components:{
    y_upload_img,
    y_choose_shipping
  },
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{}
      }
    },
    pointsMallEnable: {
      type:Boolean,
      default:false
    },
    pointsMallGiftForm:{
      type:Object,
      default() {
        return {
          goods_id:0,
          gift:0,
        }
      }
    }
  },
  watch:{
    pointsMallGiftForm(){
      this.pointsMallGiftFormEdit = this.pointsMallGiftForm;
    },
    theme_val(){
      this.$emit("theme-change",this.theme_val);
    },
    modelVal(){
      this.cate_choose = [this.modelVal.cate_parent_id,this.modelVal.cate_id]
      this.form = this.modelVal;
      this.modelVal.theme_item.forEach(item=>{
        this.theme_val.push(item.goods_theme_id)
      })
      this.theme_val = this.removeDuplicate(this.theme_val);
      if(this.modelVal.scope){
        let strArr = this.modelVal.scope.split(",");
        strArr.forEach((v,k)=>{
          strArr[k] = parseInt(v)
        })
        this.scope_show = strArr;
      }
    },
    cate_choose(){
      if (this.cate_choose.length > 1){
        this.form.cate_id = this.cate_choose[1]
        this.form.cate_parent_id = this.cate_choose[0]
      }
    }
  },
  data(){
    return{
      form:this.modelVal,
      cate_opt_list:[],
      cate_choose:[],

      theme_val:[],
      theme_opt:[],
      themeLoading:false,

      scope_show:[],
      scope_option:levelOption,
      goods_type_opt:[
        {value:1,title:"普通商品"},
        {value:2,title:"升级商品"},
        {value:3,title:"福利商品"},
      ],

      pointsMallGiftFormEdit:this.pointsMallGiftForm
    }
  },
  mounted() {
    this.loadCateOpt();
    this.loadThemeOpt();
  },
  methods:{
    removeDuplicate(arr) {
      const newArr = []
      arr.forEach(item => {
        if (!newArr.includes(item)) {
          newArr.push(item)
        }
      })
      return newArr
    },
    submit(){
      this.$emit('theme-change',this.theme_val);
      this.$emit('points-mall-gift-submit',this.pointsMallGiftFormEdit);
      this.$emit('form-submit');
    },
    scopeChange(val){
      this.modelVal.scope = val.join(",");
    },
    themeChange(val){
      val.forEach((item,key)=>{
        if (typeof item === 'string'){
          this.themeLoading = true;
          this.$u.api.shop.goods.themeEdit({title:item}).then(id=>{
            val[key] = id
            this.themeLoading = false;
            this.loadThemeOpt();
          })
        }
      })
    },
    loadThemeOpt(){
      this.$u.api.shop.goods.themeSearch({page:-1}).then(res=>{
        this.theme_opt = res.list;
      })
    },
    loadCateOpt() {
      this.$u.api.shop.goods.cateAll().then(res => {
        this.cate_opt_list = res;
      })
    },
  }
}
</script>

<style scoped>

</style>