<template>
  <div>
    <div class="flex-def flex-cCenter flex-zBetween" style="margin-bottom: 1rem">
      <div class="y-desc" style="font-size: 1rem">商品信息</div>
      <el-button @click="$router.back()" type="text" size="mini">返回列表</el-button>
    </div>
    <el-card style="min-height: 45rem">
      <el-tabs :before-leave="tabsChange" v-model="tabsActive">
        <el-tab-pane label="基础信息" name="basic">
          <basic @theme-change="themeChange" v-model="form"
                 :points-mall-enable="pointsMallEnable"
                 :points-mall-gift-form="pointsMallGiftForm"
                 @points-mall-gift-submit="pointsMallGiftSubmit"
                 @form-submit="submit"></basic>
        </el-tab-pane>
        <el-tab-pane label="商品详情" name="detail">
          <detail v-model="form" @form-submit="submit"></detail>
        </el-tab-pane>
        <el-tab-pane label="详情页轮播图" name="banner">
          <banner @reload="load" v-model="form.banner" @form-submit="submit"></banner>
        </el-tab-pane>
        <el-tab-pane label="库存/规格" name="spec">
          <spec @reload="load" v-model="form" @form-submit="submit"></spec>
        </el-tab-pane>
        <el-tab-pane v-if="[1,2].includes(form.type)" label="分润设置" name="award">
          <award @theme-change="themeChange" v-model="form" @form-submit="submit"></award>
        </el-tab-pane>
        <el-tab-pane v-if="blind_award" label="消费盲返" name="blindAward">
          <blind-award :goods-id="form.id"></blind-award>
        </el-tab-pane>
        <el-tab-pane v-if="form.id > 0" label="卡密" name="cdkey">
          <cdkey v-model="form" @form-submit="submit" @reload="load"></cdkey>
        </el-tab-pane>
<!--        <el-tab-pane label="邀请奖励" v-if="modeAreaAgentInviteSuc || modeChainInviteSuc" name="invite">-->
<!--          <template v-if="form.id > 0">-->
<!--            <inviteAreaAgent v-if="modeAreaAgentInviteSuc" :goods="form"></inviteAreaAgent>-->
<!--            <invite v-if="modeChainInviteSuc" :goods="form"></invite>-->
<!--          </template>-->
<!--        </el-tab-pane>-->
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import basic from "@/view/shop/goods/edit/basic";
import detail from "@/view/shop/goods/edit/detail";
import spec from "@/view/shop/goods/edit/spec";
import banner from "@/view/shop/goods/edit/banner";
import award from "@/view/shop/goods/edit/award";
import BlindAward from "@/view/shop/goods/edit/blindAward.vue";
import Cdkey from "@/view/shop/goods/edit/cdkey.vue";
export default {
  components: {
    Cdkey,
    BlindAward,
    basic,
    detail,
    spec,
    banner,
    award,
  },
  name: "edit",
  data() {
    return {
      type_opt: [
        {label: '普通商品', value: 0},
        {label: '升级商品', value: 1},
        {label: '免费商品', value: 2},
      ],
      form: {
        sort: 0,
        title: "",
        share_desc:"",
        cate_id: 0,
        cate_parent_id: 0,
        fake_sales: 0,
        type: 1,
        fee: 0,
        original_price: 0,
        stock: 0,
        home_pic:"",
        pic: "",
        detail: "",
        status: false,
        delivery_fee: 0,
        spec:false,
        scope:"",
        banner:"",
        recommend:false,
        shipping_id:0,

        award_base_fee:0,
        award_base_fee_unit:"",

        invite_award_scale:0,
        invite_2_award_scale:0,

        upgrade_award_agent_scale:0,
        upgrade_award_boss_scale:0,
      },
      theme_ids:[],
      tabsActive: "basic",

      pointsMallEnable:false,
      pointsMallGiftForm:{
        goods_id:0,
        gift:0,
      },

      blind_award:false,
    }
  },
  computed:{
    modeChainInviteSuc(){
      return this.$store.state.sys.global.addons.chain && this.form.type === 0
    },
    modeAreaAgentInviteSuc(){
      return this.$store.state.sys.global.addons.area_agent && [0,3,4,5,6,7].includes(this.form.type);
    },
  },
  mounted() {
    this.$u.api.shop.plugin.manage.fetchConf().then(res=>{
      this.blind_award = res.blind_award;
    })
    if (this.$route.query.id) {
      this.load(parseInt(this.$route.query.id));
    }else {
      this.loadPointsMallGift(0);
    }
  },
  methods: {
    loadPointsMallGift(id){
      this.$u.api.shop.plugin.pointsMall.shopGoodsGift({id:parseInt(id)}).then(res=>{
        this.pointsMallEnable = res.enable;
        this.pointsMallGiftForm = res.gift;
      })
    },
    submitPointsMallGift(goodsId=false){
      if (goodsId)this.pointsMallGiftForm.goods_id = goodsId;
      this.$u.api.shop.plugin.pointsMall.shopGoodsGiftEdit(this.pointsMallGiftForm)
    },
    pointsMallGiftSubmit(e){
      this.pointsMallGiftForm = {...e};
    },
    load(edit_id) {
      this.$u.api.shop.goods.one({id: parseInt(edit_id)}).then(res => {
        this.form = {...res}
        this.loadPointsMallGift(edit_id);
      })
    },
    themeChange(val) {
      this.theme_ids = val
    },
    submit(noback=false) {
      this.$u.api.shop.goods.edit({
        goods:this.form,
        theme_ids:this.theme_ids,
      }).then(async (res) => {
        await this.submitPointsMallGift(this.form.id > 0 ? false : res);
        this.$message.success("操作成功");
        if (!noback)this.$router.back();
      })
    },
    tabsChange(e) {
      let arr = ["invite","spec","blindAward"]
      if (arr.includes(e) && !this.form.id) {
        this.$message.error("请先创建商品信息")
        return false
      }
    },
  }
}
</script>

<style scoped>

</style>